import { template as template_04636d1c69724452aeb9dde861d5cc35 } from "@ember/template-compiler";
const FKText = template_04636d1c69724452aeb9dde861d5cc35(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
