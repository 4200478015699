import { template as template_efeb5b9f968a4716ad8c5ca87d4ff929 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { htmlSafe } from "@ember/template";
import PluginOutlet from "discourse/components/plugin-outlet";
export default class TopicLink extends Component {
    get url() {
        return this.args.topic.linked_post_number ? this.args.topic.urlForPostNumber(this.args.topic.linked_post_number) : this.args.topic.lastUnreadUrl;
    }
    static{
        template_efeb5b9f968a4716ad8c5ca87d4ff929(`
    {{~! no whitespace ~}}
    <PluginOutlet @name="topic-link" @outletArgs={{hash topic=@topic}}>
      {{~! no whitespace ~}}
      <a
        href={{this.url}}
        data-topic-id={{@topic.id}}
        class="title"
        ...attributes
      >{{htmlSafe @topic.fancyTitle}}</a>
      {{~! no whitespace ~}}
    </PluginOutlet>
    {{~! no whitespace ~}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
