import { template as template_3f99e6a5609f4ee6a3df44ba145b7e7f } from "@ember/template-compiler";
const FKLabel = template_3f99e6a5609f4ee6a3df44ba145b7e7f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
